<template>
  <div>
    <app-cells position="start">
      <div class="key-value-text">
        <span>Телефон:</span>
        <span>{{ guest.phone || '-' }}</span>
      </div>
      <div class="key-value-text">
        <span>Электронная почта:</span>
        <span>{{ guest.email || '-' }}</span>
      </div>
      <div class="key-value-text">
        <span>Дата рождения:</span>
        <span>{{ guest.birthday || '-' }}</span>
      </div>
      <div v-if="guest.language" class="key-value-text key-value-text--icon">
        <span>Язык:</span>
        <span v-if="guest.language.icon" v-html="guest.language.icon" class="key-value-text__icon"></span>
        <span>{{ guest.language.name || '-' }}</span>
      </div>
    </app-cells>
    <app-cells position="start">
      <div class="key-value-text">
        <span>Примечание:</span>
        <span>{{ guest.comment || '-' }}</span>
      </div>
    </app-cells>
    <app-cells position="start">
      <div class="key-value-text">
        <span>Аллергия:</span>
        <span>{{ guest.allergy || '-' }}</span>
      </div>
    </app-cells>
    <app-cells position="start"><div class="title  title--medium">История визитов</div></app-cells>
    <app-table-head
      @update-limiter="onUpdateLimiter"
      :count="table.items.length"
      :page="pagination.page"
      :all="pagination.count"
    />
    <v-client-table
      @row-click="onRowClick"
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="tableDefault"
      class="table-default table-default--dynamic"
    >
      <div slot="time_start" slot-scope="props">
        {{ formatDates(props.row.time_start, props.row.time_end) }}
      </div>
      <div slot="table" slot-scope="props">
        {{ props.row.table_number }}{{ props.row.additional_tables.length ? ',' : '' }}
        <template v-if="props.row.additional_tables.length">
          <span v-for="(item, index) in props.row.additional_tables_number" :key="item.id">
            {{ item }}{{ index !== props.row.additional_tables.length - 1 ? ',' : '' }}
          </span>
        </template>
      </div>
      <div slot="status" slot-scope="props">
        {{ props.row.status.name }}
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="onClickPagination"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__item pagination__item--prev'"
      :next-class="'pagination__item pagination__item--next'"
      :next-link-class="'pagination__link pagination__link--next'"
      :prev-link-class="'pagination__link pagination__link--prev'"
    />
    <app-sidebar-right title="Редактирование" :class="{'sidebar-manage--full': show_sidebar}" @close-sidebar="$emit('close-sidebar', !show_sidebar)">
      <form @submit.prevent="onCheckForm">
        <app-form-group label="Имя" label-for="name" required>
          <app-input
            v-model="form.name"
            placeholder="Введите имя гостя"
            id="name"
            :error="$v.form.name.$error"
          />
          <template #error>
            <div v-if="$v.form.name.$dirty && !$v.form.name.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group label="Телефон">
          <app-phone
            v-model="form.phone"
            placeholder="Введите телефон"
            @paste.native.prevent
          />
        </app-form-group>
        <app-form-group label="Язык">
          <app-cells position="start">
            <app-radio
              v-for="language in languages"
              :key="language.id"
              v-model="form.language.id"
              :value="language.id"
              name="language"
              :label="language.name"
              :icon="language.icon"
            />
          </app-cells>
        </app-form-group>
        <app-form-group label="Электронная почта" label-for="email">
          <app-input
            v-model="form.email"
            placeholder="Введите электронную почту"
            id="email"
          />
        </app-form-group>
        <app-form-group>
          <app-checkbox
            v-model="form.send_notifications"
            label="Не отправлять письма на E-mail"
          />
        </app-form-group>
        <app-form-group label="Дата рождения" label-for="birthday">
          <app-input
            @change.native="$v.form.birthday.$touch()"
            v-model="form.birthday"
            v-mask="'99.99.9999'"
            placeholder="Введите дату рождения"
            id="birthday"
            :error="$v.form.birthday.$error"
          />
          <template #error>
            <div v-if="$v.form.birthday.$dirty && !$v.form.birthday.normalDateSpan">Неправильная дата рождения</div>
            <div v-if="$v.form.birthday.$dirty && !$v.form.birthday.underscorePresent">Неправильный формат даты</div>
          </template>
        </app-form-group>
        <app-form-group>
          <div class="switcher">
            <label class="switcher__label">
              <input type="checkbox" v-model="form.is_blacklisted">
              <span class="switcher__control">
                <span class="switcher__point"></span>
              </span>
              <span class="switcher__text">Черный список</span>
            </label>
          </div>
        </app-form-group>
        <app-form-group label-for="allergy" label="Аллергия">
          <app-textarea
            v-model="form.allergy"
            id="allergy"
          />
        </app-form-group>
        <app-form-group label-for="comment" label="Примечание">
          <app-textarea
            v-model="form.comment"
            id="comment"
          />
        </app-form-group>
        <app-cells>
          <app-button ref="submit" :disabled="$v.form.$error">Сохранить</app-button>
        </app-cells>
      </form>
    </app-sidebar-right>
  </div>
</template>

<script>
import {putGuest, getBookings, getLanguages} from '@/http'
import { dateFormatting, validDate } from '@/helpers'
import { required } from 'vuelidate/lib/validators'
import { isWithinInterval, subDays } from 'date-fns'

const normalDateSpan = value => {
  if (!value) return true
  else {
    if (!value.includes('_') && validDate(value)) {
      return isWithinInterval(new Date(dateFormatting(value, 'normal-to-iso-small')), {
        start: new Date(1925, 0, 1),
        end: subDays(new Date(), 1)
      })
    } else return false
  }
}

const underscorePresent = value => {
  if (!value) return true
  else return !value.includes('_')
}

export default {
  name: 'GuestDetail',
  props: {
    guestProp: {
      type: Object
    },
    showSidebar: {
      type: Boolean,
    },
  },
  data() {
    return {
      guest: {},
      form: {},
      table: {
        items: [],
        columns: ['id', 'time_start', 'count_guests', 'table', 'status'],
        options: {
          headings: {
            id: 'ID',
            time_start: 'Дата визита',
            count_guests: 'Количество гостей',
            table: 'Столы',
            status: 'Статус'
          },
          pagination: { show: false },
          sortable: [],
          perPage: 100,
          texts: {
            noResults: 'Нет подходящих записей',
          },
          rowClassCallback: () => 'table-default__row',
          resizableColumns: false
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 1,
      },
      table_limit: 20,
      show_sidebar: false,
      languages: [],
    }
  },
  validations: {
    form: {
      name: { required },
      birthday: { normalDateSpan, underscorePresent },
    }
  },
  created() {
    this.$emit('toggle-edit', true)
    document.title = `Гость - ${ this.guestProp.name } (ID: ${ this.$route.params.id }) | BMS - ${process.env.VUE_APP_RESTARAUNT_TITLE}`
    this.guest = this.guestProp
    this.form = { ...this.guestProp }
    this.form.send_notifications = !this.form.send_notifications
    this.fetchBookings()
  },
  watch: {
    guestProp(newVal) {
      this.guest = newVal
      this.form = { ...newVal }
      this.form.send_notifications = !this.form.send_notifications
      document.title = `Гость - ${ this.guestProp.name } (ID: ${ this.$route.params.id }) | BMS - ${process.env.VUE_APP_RESTARAUNT_TITLE}`
    },
    showSidebar(newVal) {
      this.show_sidebar = newVal
      if (!this.languages.length) {
        getLanguages()
          .then(response => {
            this.languages = response.data
          })
      }
    }
  },
  methods: {
    formatDates(date, dateSecond) {
      return `${dateFormatting(date, 'iso-to-normal')} - ${dateFormatting(dateSecond, 'iso-to-normal').slice(-5)}`
    },
    fetchBookings(page = this.pagination.page, page_size = this.table_limit) {
      getBookings({ page, page_size, guest: this.$route.params.id }).then(response => {
        this.table.items = response.data.results
        this.pagination.pages = response.data.pages
        this.pagination.count = response.data.count
      })
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание',
          text: 'Проверьте правильность заполнения полей формы'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      putGuest(this.$route.params.id, this.normalizeForm())
        .then(() => {
          this.$refs.submit.preload = false
          this.show_sidebar = false
          this.$emit('form-send')
          this.$emit('close-sidebar', this.show_sidebar)
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Гость сохранен'
          })
        })
        .catch(() => {
          this.$refs.submit.preload = false
        })
    },
    normalizeForm() {
      const { ...normalizedForm } = this.form

      return {
        name: normalizedForm.name,
        phone: normalizedForm.phone ? normalizedForm.phone : null,
        email: normalizedForm.email,
        birthday: dateFormatting(normalizedForm.birthday, 'normal-to-iso-small'),
        allergy: normalizedForm.allergy,
        comment: normalizedForm.comment,
        language: normalizedForm.language.id,
        send_notifications: !normalizedForm.send_notifications,
        is_blacklisted: normalizedForm.is_blacklisted,
      }
    },
    onRowClick(element) {
      this.$router.push({ name: 'booking-detail', params: { id: element.row.id } })
    },
    onUpdateLimiter(data) {
      this.table_limit = data
      this.$refs.tableDefault.setLimit(this.table_limit)
      this.fetchBookings(this.pagination.page, this.table_limit)
    },
    onClickPagination(page) {
      this.fetchBookings(page, this.table_limit)
    },
  }
}
</script>
